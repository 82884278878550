import { AxiosPromise } from 'axios';
import Toastify from 'toastify';
import { each } from 'lodash';
import { request } from './index';
import { ApiResellerObject } from './resellers';
import { FilterObject } from '../components/AdvancedTable';

export interface ApiUserObject {
    id: number,
    hash_id: string,
    reseller_id?: number,
    reseller?: ApiResellerObject,
    customer_ids?: number[],
    role_id: number,
    first_name: string,
    affix: string,
    last_name: string,
    full_name: string,
    email_address: string,
    '2fa_type': string,
    is_active: boolean,
    send_bcc: boolean,
    debtor_link: string,
}

export default {
    delete: (id: number | string): AxiosPromise => {
        return request.delete(`users/${id}`);
    },
    list: (filter?: Partial<FilterObject>, include?: string[], role?: string): AxiosPromise => {
        const query: any = [];

        if (filter) {
            each(filter, (v, k) => {
                query.push(`${k}=${v}`);
            });
        }

        return request.get(`users?${query.join('&')}${include ? `&with=${include.join(',')}` : ''}&role=${role || ''}`);
    },
    store: (data: any): AxiosPromise<ApiUserObject> => {
        return request.post(`users`, data);
    },
    patch: (id: number | string, data: any): AxiosPromise<ApiUserObject> => {
        return request.patch(`users/${id}?with=customers`, data);
    },
    sendInvite: (id: number | string): AxiosPromise => {
        return request.post(`/users/${id}/send-invite`).then((res) => {
            Toastify.success('Uitnodiging succesvol verstuurd');
            return res;
        });
    },
    show: (id: number | string, w: string[] = []): AxiosPromise<ApiUserObject> => {
        return request.get(`users/${id}?with=${w.join(',')}`);
    },
};
