import { find } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import { Segment, Statistic, Divider, Input, Table, Dropdown, Menu, Icon, Checkbox, Button } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PageHeader from '../../components/Layout/PageHeader';
import AmountPerPeriod from './AmountPerPeriod';
import PayedVsStatus from './PayedVsStatus';
import ActionsSent from './ActionsSent';
import InvoiceStatus from './InvoiceStatus';
import AgeAnalysis from './AgeAnalysis';
import DsoStats from './DsoStats';
import styles from './styles.module.scss';
import { money, number } from '../../lib/utils';
import { getToken, request } from '../../api';
import DomainLink from '../../components/DomainLink';
import { AppState } from '../../store';
import { ApiAuthObject } from '../../api/auth';
import Vdp from './Vdp';

interface DashboardViewProps extends RouteComponentProps {
    auth?: ApiAuthObject,
}

interface DashboardViewState {
    debtors: any,
    stats: {
        date: any,
        total: number,
        amount: number,
        notdue: number,
        dso: number,
        dso_target: number,
        currencies: string[],
        cmsi: number,
    },
    globalFilter: {
        query: string,
        trigger: number,
        r9: boolean,
    }
    isSticky: boolean,
    currencies: any[],
    currency?: string,
    editDso: boolean,
    onlyOpen: boolean,
}

class DashboardView extends React.Component<DashboardViewProps & WithTranslation, DashboardViewState> {
    private dsoTimer: any;
    private searchTimer: any = 0;

    constructor(props: DashboardViewProps & WithTranslation) {
        super(props);
    
        this.state = {
            debtors: false,
            stats: {
                date: new Date(),
                total: 0,
                amount: 0,
                notdue: 0,
                dso: 0,
                dso_target: 20,
                currencies: [],
                cmsi: 0,
            },
            globalFilter: {
                query: '',
                trigger: 0,
                r9: false,
            },
            isSticky: false,
            currencies: [],
            currency: undefined,
            editDso: false,
            onlyOpen: false,
        };
    }

    componentDidMount = () => {
        this.getCurrencies();

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (e: any) => {
        this.setState({ isSticky: window.scrollY > 110 });
    }

    getCurrencies = () => {
        request.get('dashboard/currencies').then(({ data }) => {
            this.setState({
                currencies: data.map((o: string, i: number) => ({
                    key: `curr-${i}`,
                    value: o,
                    text: o,
                })),
                currency: data[0],
            }, this.fetch);
        });
    }

    fetch = () => {
        const { currency, globalFilter, onlyOpen } = this.state;

        const q = globalFilter.query;
        const r9 = globalFilter.r9;

        request.get(`dashboard/stats?currency=${currency}&q=${q}${r9 ? `&r9=1` : ''}`).then(({ data }) => {
            this.setState({
                stats: data,
            })
        });

        request.get(`dashboard/top-debtors?currency=${currency}&q=${q}${r9 ? `&r9=1` : ''}&open=${onlyOpen ? 1 : 0}`).then(({ data }) => {
            this.setState({
                debtors: data,
            })
        });
    }

    setDso = (e: any, data: any) => {
        clearTimeout(this.dsoTimer);

        const { stats } = this.state;
        stats.dso_target = data.value;
        this.setState({ stats });

        this.dsoTimer = setTimeout(() => {
            request.post('dashboard/set-dso', { dso: data.value });
        }, 300);
    }

    handleSearch = (query: string = '', r9: boolean = false) => {
        clearTimeout(this.searchTimer);
        this.setState({
            globalFilter: {
                ...this.state.globalFilter,
                query,
                r9,
            },
        });

        this.searchTimer = setTimeout(() => {
            const { globalFilter } = this.state;

            this.setState({
                globalFilter: {
                    ...globalFilter,
                    query,
                    trigger: globalFilter.trigger + 1,
                    r9,
                },
            }, this.fetch);
        }, 300);
    }

    createReport = () => {
        const { currency } = this.state;
        const files: any[] = [];

        ['1', '2'].forEach((i) => {
            const node = document.querySelector(`#chart${i} .highcharts-root`);
            if (!node) return;
            const svg = new XMLSerializer().serializeToString(node);
            const canvas = document.querySelector('canvas');
            if (!canvas) return;
            const ctx = canvas.getContext('2d');
            if (!ctx) return;
            const img = new Image();

            img.onload = function () {
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;

                ctx.drawImage(img, 0, 0, img.width, img.height);

                canvas.toBlob((blob) => {
                    files.push(blob);

                    if (files.length === 2) {
                        const fd = new FormData();
                        fd.append('file-1', files[0]);
                        fd.append('file-2', files[1]);

                        request.post('reports', fd).then(({ data }) => {
                            window.open(`${data.report}?_token=${getToken()}&currency=${currency}`, '_blank');
                        });
                    }
                });
            };
            img.src = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(svg)));
        });
    }

    // render
    render = () => {
        const { auth, t } = this.props;
        const { stats, debtors, currencies, currency, isSticky, globalFilter, editDso } = this.state;

        if (!debtors || currencies.length <= 0) {
            return null;
        }

        return (
            <div className="pageContainer dashboard" style={{ position: 'relative' }}>
                <PageHeader title="Dashboard">
                    {currencies.length > 1 && (
                        <Dropdown
                            options={currencies}
                            selection
                            value={currency}
                            onChange={(e: any, data: any) => this.setState({ currency: data.value }, this.fetch)}
                        />
                    )}
                </PageHeader>

                <canvas
                    style={{
                        display: 'none',
                        height: 400,
                        width: 1080,
                    }}
                />

                <div className={styles.filter}>
                    <div className={isSticky ? styles.fixedFilter : ''}>
                        <div>
                            <Menu compact>
                                <Menu.Item>
                                    <Input
                                        style={{ width: 250 }}
                                        placeholder={t('dashboard.filter')}
                                        transparent
                                        icon="search"
                                        iconPosition="left"
                                        value={globalFilter.query}
                                        onChange={(e, data) => this.handleSearch(data.value)}
                                    />
                                </Menu.Item>
                                {auth && auth.selected_customer_id === 177 && (
                                    <Menu.Item>
                                        <Checkbox
                                            label="Alleen R9"
                                            checked={globalFilter.r9}
                                            onChange={(e, data) => this.handleSearch('', data.checked)}
                                        />
                                    </Menu.Item>
                                )}
                                {auth && [109, 4].includes(auth.id) && (
                                    <Menu.Item>
                                        <Button
                                            basic
                                            content="Maak rapportage"
                                            onClick={() => this.createReport()}
                                        />
                                    </Menu.Item>
                                )}
                            </Menu>
                        </div>
                    </div>
                </div>

                <Vdp />

                <Divider horizontal style={{ marginBottom: 20 }}>{t('dashboard.g1')}</Divider>

                <Segment>
                    <InvoiceStatus history={this.props.history} currency={currency} globalFilter={globalFilter} />
                </Segment>

                <Divider horizontal style={{ marginTop: 40, marginBottom: 20 }}>{t('dashboard.stats')}</Divider>

                <div className={styles.stats}>
                    <div className={styles.stat}>
                        <Segment className={styles.segment}>
                            <Statistic size='mini'>
                                <Statistic.Label className={styles.label}>{t('dashboard.s1')}</Statistic.Label>
                                <Statistic.Value className={styles.value}>{moment(stats.date).format('DD/MM/YYYY')}</Statistic.Value>
                            </Statistic>
                        </Segment>
                    </div>
                    <div className={styles.stat}>
                        <Segment className={styles.segment}>
                            <Statistic size='mini'>
                                <Statistic.Label className={styles.label}>{t('dashboard.s2')}</Statistic.Label>
                                <Statistic.Value className={styles.value}>{money(stats.total, 2, currency)}</Statistic.Value>
                            </Statistic>
                        </Segment>
                    </div>
                    <div className={styles.stat}>
                        <Segment className={styles.segment}>
                            <Statistic size='mini'>
                                <Statistic.Label className={styles.label}>{t('debtors.open_saldo')}</Statistic.Label>
                                <Statistic.Value className={styles.value}>{money(stats.amount, 2, currency)}</Statistic.Value>
                            </Statistic>
                        </Segment>
                    </div>
                    <div className={styles.stat}>
                        <Segment className={styles.segment} style={{ borderColor: '#ff8000' }}>
                            <Statistic size='mini'>
                                <Statistic.Label className={styles.label}>{t('debtors.expired_saldo')}</Statistic.Label>
                                <Statistic.Value className={styles.value} style={{ fontWeight: 'bold' }}>{money(stats.amount - stats.notdue, 2, currency)}</Statistic.Value>
                            </Statistic>
                        </Segment>
                    </div>
                    {!editDso ? (
                        <div className={styles.stat}>
                            <Segment className={styles.segment}>
                                <Statistic size='mini'>
                                    <Statistic.Label className={styles.label}>{t('dashboard.s3')}</Statistic.Label>
                                    <Statistic.Value className={styles.value}>{number(stats.dso)} {t('dashboard.days')}</Statistic.Value>
                                    <p
                                        onClick={() => this.setState({ editDso: true })}
                                        style={{ marginTop: 5, fontSize: 12, cursor: 'pointer' }}
                                    >
                                        {t('dashboard.s4')}: {stats.dso_target}
                                    </p>
                                </Statistic>
                            </Segment>
                        </div>
                    ) : (
                        <div className={styles.stat}>
                            <Segment className={styles.segment}>
                                <Statistic size='mini'>
                                    <Statistic.Label className={styles.label}>{t('dashboard.s4')}</Statistic.Label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Input
                                            value={stats.dso_target}
                                            className={styles.input}
                                            onChange={this.setDso}
                                        />
                                        <Icon name="save" color="grey" style={{ marginLeft: 10, fontSize: 18, cursor: 'pointer' }} onClick={() => this.setState({ editDso: false })} />
                                    </div>
                                </Statistic>
                            </Segment>
                        </div>
                    )}
                    <div className={styles.stat}>
                        <Segment className={styles.segment}>
                            <Statistic size='mini'>
                                <Statistic.Label className={styles.label}>CMSI360</Statistic.Label>
                                <Statistic.Value className={styles.value} style={{ fontWeight: 'bold' }}>{money(stats.cmsi, 2, currency)}</Statistic.Value>
                            </Statistic>
                        </Segment>
                    </div>
                </div>

                <Divider horizontal style={{ marginTop: 40, marginBottom: 20 }}>{t('dashboard.g2')}</Divider>

                <Segment>
                    <AgeAnalysis history={this.props.history} currency={currency} globalFilter={globalFilter} />
                </Segment>

                <Divider horizontal style={{ marginTop: 40, marginBottom: 20 }}>{t('dashboard.g3')}</Divider>
                <DsoStats history={this.props.history} globalFilter={globalFilter} />

                <Divider horizontal style={{ marginTop: 40, marginBottom: 20 }}>{t('dashboard.g4')}</Divider>
                <AmountPerPeriod history={this.props.history} currency={currency} globalFilter={globalFilter} />

                <Divider horizontal style={{ marginTop: 40, marginBottom: 20 }}>{t('dashboard.g5')}</Divider>
                <PayedVsStatus history={this.props.history} currency={currency||'€'} globalFilter={globalFilter} />

                <Divider horizontal style={{ marginTop: 40, marginBottom: 20 }}>{t('dashboard.g6')}</Divider>
                <ActionsSent history={this.props.history} currency={currency||'€'} globalFilter={globalFilter} />

                <Divider horizontal style={{ marginTop: 40, marginBottom: 20 }}>{t('dashboard.t1')}</Divider>
                <Segment>
                    <Table striped selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>{t('debtors.number')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('debtors.name')}</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Status</Table.HeaderCell>
                                <Table.HeaderCell collapsing textAlign="right" singleLine>{t('debtors.expired_saldo')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {debtors.amount.map((d: any) => (
                                <Table.Row key={`td-${d.id}`}>
                                    <Table.Cell collapsing>
                                        <DomainLink to={`/debtors/${d.id}/edit`}>
                                            {d.number}
                                        </DomainLink>
                                    </Table.Cell>
                                    <Table.Cell>{d.name}</Table.Cell>
                                    <Table.Cell collapsing>{d.status}</Table.Cell>
                                    <Table.Cell collapsing textAlign="right" singleLine>
                                        {money(d.total, 2, currency)}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>

                <Divider horizontal style={{ marginTop: 40, marginBottom: 20 }}>{t('dashboard.t2')}</Divider>
                <Segment>
                    <Table striped selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>{t('debtors.number')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('debtors.name')}</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Status</Table.HeaderCell>
                                <Table.HeaderCell collapsing textAlign="right" singleLine>{t('dashboard.t2t')}</Table.HeaderCell>
                                <Table.HeaderCell collapsing textAlign="right" singleLine>Openstaand saldo</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {debtors.count.map((d: any) => {
                                const saldo = find(debtors.saldo, { debtor_id: d.id });
                                
                                return (<Table.Row key={`td-${d.id}`}>
                                    <Table.Cell collapsing>
                                        <DomainLink to={`/debtors/${d.id}/edit`}>
                                            {d.number}
                                        </DomainLink>
                                    </Table.Cell>
                                    <Table.Cell>{d.name}</Table.Cell>
                                    <Table.Cell collapsing>{d.status}</Table.Cell>
                                    <Table.Cell collapsing textAlign="right" singleLine>
                                        {number(d.count)}
                                    </Table.Cell>
                                    <Table.Cell collapsing textAlign="right" singleLine>
                                        {saldo ? money(saldo.total) : '-'}
                                    </Table.Cell>
                                </Table.Row>);
                            })}
                        </Table.Body>
                    </Table>
                </Segment>

                <Divider horizontal style={{ marginTop: 40, marginBottom: 20 }}>{t('dashboard.t3')}</Divider>

                <Menu>
                    <Menu.Item>
                        <Checkbox
                            toggle
                            label={t('invoices.only_open')}
                            checked={this.state.onlyOpen}
                            onChange={(e: any, data: any) => this.setState({ onlyOpen: data.checked }, this.fetch)}
                        />
                    </Menu.Item>
                </Menu>

                <Segment>
                    <Table striped selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>{t('debtors.number')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('debtors.name')}</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Status</Table.HeaderCell>
                                <Table.HeaderCell collapsing textAlign="right" singleLine>{t('dashboard.dso')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {debtors.dso.map((d: any) => (
                                <Table.Row key={`td-${d.id}`}>
                                    <Table.Cell collapsing>
                                        <DomainLink to={`/debtors/${d.id}/edit`}>
                                            {d.number}
                                        </DomainLink>
                                    </Table.Cell>
                                    <Table.Cell>{d.name}</Table.Cell>
                                    <Table.Cell collapsing>{d.status}</Table.Cell>
                                    <Table.Cell collapsing textAlign="right" singleLine>
                                        {number(d.dso, 0)}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>
            </div>
        );
    }
}

export default withTranslation('common')(connect((state: AppState) => ({
    auth: state.auth.user,
}))(DashboardView));
