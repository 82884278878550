import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Input, Menu } from 'semantic-ui-react';
import moment from 'moment';
import Toastify from 'toastify';
import api, { request, getToken } from '../../api';
import { ApiDebtorObject } from '../../api/debtors';
import AdvancedTable, { AdvancedTableAction, FilterObject } from '../../components/AdvancedTable';
import DomainLink from '../../components/DomainLink';
import PageHeader from '../../components/Layout/PageHeader';
import { money, number } from '../../lib/utils';
import { AppState } from '../../store';
import { ApiAuthObject } from '../../api/auth';
import { ApiRiMatchObject } from '../../api/rimatch';
const config = require('../../config.json');

interface RiMatchListViewProps {
    auth: ApiAuthObject,
}

interface RiMatchListViewState {
    matches: ApiRiMatchObject[],
    pagination?: any,
    isLoading: boolean,
    tableFilter?: any,
    scenario: number,
    uploadFile?: File,
    totals: any,
}

class RiMatchListView extends React.Component<RiMatchListViewProps, RiMatchListViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            matches: [],
            pagination: undefined,
            isLoading: false,
            tableFilter: undefined,
            scenario: 3,
            uploadFile: undefined,
            totals: {
                amount: 0,
                saldo: 0,
            }
        };
    }

    /**
     * Fetch
     */
    fetch = (filter?: FilterObject) => {
        const { scenario } = this.state;
        this.setState({ isLoading: true, tableFilter: filter });

        api.listRiMatches(scenario).then(({ data }) => {
            let totals = {
                amount: 0,
                saldo: 0,
            };

            data.data.forEach((item: any) => {
                totals.amount += (item.invoice.amount || 0) * 1;
                totals.saldo += (item.saldo || 0) * 1;
            });

            this.setState({
                matches: data.data,
                pagination: data.meta,
                isLoading: false,
                totals,
            });
        });
    }

    startCsvSync = () => {
        const { uploadFile } = this.state;

        if (uploadFile) {
            const data = new FormData();
            data.append('file', uploadFile);

            request.post(`ri-matches`, data).then(() => {
                Toastify.success('De match wordt uitgevoerd');
            }).catch(() => {
                Toastify.error('Ongeldig bestand');
            });
        }
    }

    deleteMatch = (matchId: number) => {
        request.delete(`ri-matches/${matchId}`).then(() => {
            this.fetch();
            Toastify.success('Verwijderd');
        });
    }

    // render
    render = () => {
        const { auth } = this.props;
        const { pagination, matches, isLoading, scenario, totals } = this.state;

        return (
            <div className="pageContainer">
                <PageHeader title="RI Matches">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {auth.role_id !== 8 && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    style={{ width: 250 }}
                                    accept=".csv"
                                    fluid
                                    type="file"
                                    onChange={(e: any) => this.setState({
                                        uploadFile: e.target.files[0],
                                    })}
                                />
                                <Button primary style={{ marginLeft: 5 }} onClick={this.startCsvSync}>Importeren</Button>
                            </div>
                        )}
                        <a className="ui basic button" href={`${config.baseUrl}ri-matches/export?export=1&scenario=${scenario}&_token=${getToken()}`} target="_blank" rel="noopener noreferrer">Exporteren</a>
                    </div>
                </PageHeader>

                <Menu tabular attached="top">
                    <Menu.Item
                        content="LFH corrigeren"
                        active={scenario === 3}
                        onClick={() => this.setState({ scenario: 3 }, this.fetch)}
                    />
                    <Menu.Item
                        content="RI betaald/restant"
                        active={scenario === 2}
                        onClick={() => this.setState({ scenario: 2 }, this.fetch)}
                    />
                    <Menu.Item
                        content="RI betaald"
                        active={scenario === 1}
                        onClick={() => this.setState({ scenario: 1 }, this.fetch)}
                    />
                </Menu>
                <AdvancedTable
                    segmentProps={{ attached: 'bottom'}}
                    name="rimatches-index"
                    items={matches}
                    header={[{
                        id: 'debtor',
                        title: 'Debiteur',
                    }, {
                        id: 'number',
                        title: 'Factuurnr.',
                    }, {
                        id: 'amount',
                        title: 'Openstaand bedrag',
                    }, {
                        id: 'saldo',
                        title: 'RI',
                    }, {
                        id: 'status',
                        title: scenario === 2 ? 'Debiteurstatus' : 'Laatste status',
                    }, {
                        id: 'br',
                        title: 'BR',
                    }, {
                        id: 'bi',
                        title: 'BI',
                    }]}
                    renderRow={(item: ApiRiMatchObject) => ({
                        cells: [{
                            header: 'debtor',
                            render: <DomainLink primary to={`/debtors/${item.debtor_id}/edit`}>{item.debtor ? `${item.debtor.number} - ${item.debtor.name}` : ''}</DomainLink>,
                        }, {
                            header: 'number',
                            render: <DomainLink primary to={`/invoices/${item.invoice_id}/edit`}>{item.invoice.number}</DomainLink>,
                            cellProps: { collapsing: true }
                        }, {
                            header: 'amount',
                            render: money(item.invoice.amount || 0, 2, item.invoice.currency),
                            cellProps: { textAlign: 'right' }
                        }, {
                            header: 'saldo',
                            render: money(item.saldo || 0, 2, item.invoice.currency),
                            cellProps: { textAlign: 'right' }
                        }, {
                            header: 'status',
                            render: scenario !== 2 && item.prev_status ? (<div>
                                    {item.prev_status.name}<br />
                                    {item.invoice.last_action_at && <small>{moment(item.invoice.last_action_at).format('DD/MM/YYYY')}</small>}
                                </div>) : (scenario === 2 ? item.debtor.status : ''),
                        }, {
                            header: 'br',
                            render: <Icon name={item.invoice.calculate_interest ? 'check' : 'times'} color={item.invoice.calculate_interest ? 'green' : 'red'} />,
                            cellProps: { collapsing: true }
                        }, {
                            header: 'bi',
                            render: <Icon name={item.invoice.calculate_incasso ? 'check' : 'times'} color={item.invoice.calculate_incasso ? 'green' : 'red'} />,
                            cellProps: { collapsing: true }
                        }],
                        actions: auth.role_id === 8 ? undefined : [<AdvancedTableAction 
                            key={`action-1-${item.id}`}
                            iconName="trash alternate outline"
                            text="Verwijderen"
                            onConfirm={() => this.deleteMatch(item.id)}
                            confirmContent="Weet u zeker dat u deze match wilt verwijderen?"
                        />]
                    })}
                    footer={[{
                        id: 'amount',
                        content: money(totals.amount),
                    }, {
                        id: 'saldo',
                        content: money(totals.saldo),
                    }]}
                    onFilter={this.fetch}
                    isLoading={isLoading}
                    pagination={pagination}
                />
            </div>
        );
    }
}

export default connect((state: AppState) => ({
    auth: state.auth.user,
}), {})(RiMatchListView);
