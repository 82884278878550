import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Popup, Input } from 'semantic-ui-react';
import Toastify from 'toastify';
import { WithTranslation, withTranslation } from 'react-i18next';
import api, { getToken } from '../../api';
import { ApiDebtorObject } from '../../api/debtors';
import AdvancedTable, { AdvancedTableAction, FilterObject } from '../../components/AdvancedTable';
import DomainLink from '../../components/DomainLink';
import PageHeader from '../../components/Layout/PageHeader';
import { money, number } from '../../lib/utils';
import { AppState } from '../../store';
import { ApiAuthObject } from '../../api/auth';
import moment from 'moment';
const config = require('../../config.json');

interface DebtorsListViewProps {
    auth: ApiAuthObject,
}

interface DebtorsListViewState {
    debtors: ApiDebtorObject[],
    pagination?: any,
    isLoading: boolean,
    tableFilter?: any,
    editInline: number,
    columnFilters: {
        collect: any[],
        credit_limit: any[],
    },
    totals: {
        amount: number,
        due: number,
        credit_limit: number,
    },
}

class DebtorsListView extends React.Component<DebtorsListViewProps & WithTranslation, DebtorsListViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            debtors: [],
            pagination: undefined,
            isLoading: false,
            tableFilter: undefined,
            editInline: 0,
            columnFilters: {
                collect: [],
                credit_limit: [],
            },
            totals: {
                amount: 0,
                credit_limit: 0,
                due: 0,
            }
        };
    }

    /**
     * Fetch
     */
    fetch = (filter?: FilterObject) => {
        const { columnFilters } = this.state;
        const f = filter || this.state.tableFilter;
        this.setState({ isLoading: true, tableFilter: f });

        api.listDebtors(f, undefined, columnFilters).then(({ data }) => {
            this.setState({
                debtors: data.data,
                pagination: data.meta,
                isLoading: false,
                totals: data.totals,
            });
        });
    }

    filterColumn = (values: any[], column: string) => {
        this.setState({
            columnFilters: {
                ...this.state.columnFilters,
                [column]: values,
            },
        }, this.fetch);
    }

    /**
     * Delete debtor
     */
    deleteDebtor = (id: number) => {
        const { t } = this.props;
        api.deleteDebtor(id).then(() => {
            Toastify.success(t('debtors.confirm_delete'));
            this.fetch();
        });
    }

    handleDebtorStatusChange = (newStatus: any, index?: number) => {
        const { debtors } = this.state;
        
        if (index !== undefined && debtors[index]) {
            debtors[index] = {
                ...debtors[index],
                status: newStatus,
            } as ApiDebtorObject;
            this.setState({ debtors });
        }
    }

    saveDebtorStatus = (debtor: ApiDebtorObject) => {
        api.patchDebtor(debtor.id, debtor).then(() => {
            this.setState({ editInline: 0 });
        });
    }

    editInline = (debtorId: number) => {
        this.setState({
            editInline: debtorId,
        }, () => {
            const input = document.getElementById(`inline-deb-${debtorId}`);
            if (input) input.focus();
        });
    }

    // render
    render = () => {
        const { auth, t } = this.props;
        const { pagination, debtors, isLoading, totals } = this.state;

        return (
            <div className="pageContainer">
                <PageHeader title={t('debtors.title')}>
                    {auth.role_id !== 6 && auth.role_id !== 8 && (<DomainLink to="/debtors/create">
                        <Button primary>{t('debtors.add_debtor')}</Button>
                    </DomainLink>)}
                    {(auth.selected_customer_id === 177 || auth.selected_customer_id === 186) && (<DomainLink to="/debtors/subscriptions">
                        <Button primary>Contracten</Button>
                    </DomainLink>)}
                    <a className="ui basic button" href={`${config.baseUrl}debtors/export?export=1&${qs.stringify(this.state.tableFilter)}&_token=${getToken()}`} target="_blank" rel="noopener noreferrer">{t('general.export')}</a>
                </PageHeader>

                <AdvancedTable
                    name="debtor-index"
                    items={debtors}
                    header={[{
                        id: 'number',
                        title: t('debtors.number'),
                        sortable: 'number',
                    }, {
                        id: 'name',
                        title: t('debtors.name'),
                        sortable: 'name',
                    }, {
                        id: 'status',
                        title: 'Status',
                        sortable: 'status',
                    }, {
                        id: 'dso',
                        title: 'DSO',
                        sortable: 'dso',
                    }, {
                        id: 'open_invoices',
                        title: `# ${t('invoices.title').toLocaleLowerCase()}`,
                        sortable: 'open_invoices',
                    }, {
                        id: 'invoices_sum',
                        title: t('debtors.open_saldo'),
                        sortable: 'invoices_sum',
                    }, {
                        id: 'invoices_expired',
                        title: t('debtors.expired_saldo'),
                        sortable: 'invoices_expired',
                    }, {
                        id: 'credit_limit',
                        title: auth.selected_customer_id === 177 ? 'R9' : t('debtors.creditlimit'),
                        sortable: auth.selected_customer_id === 177 ? undefined : 'credit_limit',
                        filter: [{id: 1, name: 'Wel'}, {id: 0, name: 'Niet'}],
                        onFilter: auth.selected_customer_id === 177 ? this.filterColumn : undefined,
                    }, {
                        id: 'collect',
                        title: t('debtors.collect'),
                        filter: [{id: 1, name: 'Ja'}, {id: 0, name: 'Nee'}],
                        onFilter: this.filterColumn,
                    }]}
                    renderRow={(item: ApiDebtorObject, index?: number) => ({
                        cells: [{
                            header: 'number',
                            render: item.number,
                            cellProps: { collapsing: true }
                        }, {
                            header: 'name',
                            render: <DomainLink primary to={`/debtors/${item.id}/edit`}>{item.name}</DomainLink>,
                        }, {
                            header: 'status',
                            render: this.state.editInline !== item.id
                            ? item.status
                            : <Input
                                value={item.status || ''}
                                id={`inline-deb-${item.id}`}
                                onChange={(e: any, { value }) => this.handleDebtorStatusChange(value, index)}
                                onBlur={() => this.saveDebtorStatus(item)}
                            />,
                            cellProps: {
                                onClick: () => this.editInline(item.id),
                            }
                        }, {
                            header: 'invoices_sum',
                            render: money(item.invoices_sum || 0),
                            cellProps: { collapsing: true, textAlign: 'right' },
                        }, {
                            header: 'invoices_expired',
                            render: money(item.invoices_expired || 0),
                            cellProps: { collapsing: true, textAlign: 'right' },
                        }, {
                            header: 'open_invoices',
                            render: number(item.open_invoices || 0),
                            cellProps: { collapsing: true, textAlign: 'right' },
                        }, {
                            header: 'dso',
                            render: number(Math.abs(item.dso || 0)),
                            cellProps: { collapsing: true, textAlign: 'right' },
                        }, {
                            header: 'credit_limit',
                            render: auth.selected_customer_id === 177 ? (item.subscription_expired ? <Popup trigger={<Icon name="flag outline" color="red" />} size="tiny" inverted content={moment(item.subscription).format('DD-MM-YYYY')} /> : '') : money(item.credit_limit || 0),
                            cellProps: { collapsing: true, textAlign: 'right' },
                        }, {
                            header: 'collect',
                            render: item.collect ? <Icon name="check" color="green" /> : '',
                            cellProps: { collapsing: true, textAlign: 'center' }
                        }],
                        actions: auth.role_id === 8 ? [] : [
                            <AdvancedTableAction 
                                key={`action-1-${item.id}`}
                                to={`/debtors/${item.id}/edit`}
                                iconName="pencil"
                                text={t('general.edit')}
                            />,
                            auth.role_id !== 6 && auth.super_login && <><AdvancedTableAction key={`action-2-${item.id}`} divider />
                            <AdvancedTableAction 
                                key={`action-6-${item.id}`}
                                iconName="trash alternate outline"
                                text={t('general.delete')}
                                onConfirm={() => this.deleteDebtor(item.id)}
                                confirmContent={t('debtors.confirm_delete')}
                            /></>,
                        ]
                    })}
                    footer={[{
                        id: 'invoices_sum',
                        content: money(totals.amount),
                    }, {
                        id: 'invoices_expired',
                        content: money(totals.due || 0),
                    }, {
                        id: 'credit_limit',
                        content: money(totals.credit_limit),
                        showIf: auth.selected_customer_id !== 177,
                    }]}
                    onFilter={this.fetch}
                    isLoading={isLoading}
                    pagination={pagination}
                />
            </div>
        );
    }
}

export default withTranslation('common')(connect((state: AppState) => ({
    auth: state.auth.user,
}), {})(DebtorsListView));
