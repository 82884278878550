import React from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Grid, Segment, Header, Input, Icon } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Toastify from 'toastify';
import { request } from '../api';
import { ApiAuthObject } from '../api/auth';
import config from '../config.json';
import PageHeader from '../components/Layout/PageHeader';
import { AppState } from '../store';
const twinfields = require('./twinfields.png');


interface ImportViewProps {
    auth: ApiAuthObject,
}

interface ImportViewState {
    details: any,
    isLoading: boolean | string,
    uploadFile?: File,
}

class ImportView extends React.Component<ImportViewProps & WithTranslation, ImportViewState> {
    constructor(props: any) {
        super(props);

        this.state = {
            details: {},
            isLoading: false,
            uploadFile: undefined,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        this.fetchDetails();
    }

    /**
     * Fetch details
     */
    fetchDetails = () => {
        request.get('connect/details').then(({ data }) => {
            this.setState({
                details: data,
            });
        });
    }

    /**
     * Set division
     */
    setDivision = (division: number, type: string) => {
        const { t } = this.props;

        request.post('connect/set-division', {
            division,
            type,
        }).then(() => {
            this.fetchDetails();
            Toastify.success(t('synchronize.admin_set'));
        });
    }

    disconnect = (type: string) => {
        this.setState({ isLoading: type });
        request.post('connect/disconnect').then(() => {
            this.fetchDetails();
            Toastify.success('Succesvol ontkoppeld');
            this.setState({ isLoading: false });
        });
    }

    /**
     * Start sync
     */
    startSync = (type: string) => {
        const { t } = this.props;

        this.setState({ isLoading: type });
        request.post(`connect/start-sync/${type}`).then(() => {
            this.setState({ isLoading: false });
            Toastify.success(t('synchronize.in_progress'));
        }).catch(() => {
            Toastify.error('Er is nog een synchronisatie actief, probeer het op een later moment nog eens');
        });
    }

    /**
     *
     */
    startCsvSync = () => {
        const { t } = this.props;
        const { uploadFile } = this.state;

        if (uploadFile) {
            const data = new FormData();
            data.append('file', uploadFile);

            request.post(`connect/start-sync/csv`, data).then(() => {
                Toastify.success(t('synchronize.in_progress'));
            });
        }
    }

    // render
    render = () => {
        const { auth, t } = this.props;
        const { details } = this.state;

        if (!auth) {
            return null;
        }

        return (
            <div className="pageContainer">
                <PageHeader title={t('synchronize.title')} />

                <Grid columns="equal">
                    <Grid.Row>
                        <Grid.Column>
                            <Segment loading={this.state.isLoading === 'exact'}>
                                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                                    <img src="https://files.exact.com/static/rainbow/v0/img/exact-logo.svg" alt="" style={{ width: 220 }} />
                                    {details.exact && details.exact.current_division ? (
                                        <>
                                        <Button positive>{t('synchronize.linked')}</Button>
                                        {auth.super_login && <Button basic onClick={() => this.disconnect('exact')}>Ontkoppelen</Button>}
                                        </>
                                    ) : (
                                        <a
                                            className="ui basic button"
                                            href={`${config.baseUrl}connect/exact?state=${auth.selected_customer ? auth.selected_customer.hash_id : ''}`}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {t('synchronize.link')}
                                        </a>
                                    )}
                                </div>
                                {details.exact && Object.keys(details.exact.divisions).length > 0 && (<>
                                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                                        <Button
                                            basic
                                            onClick={() => this.startSync('exact')}
                                        >
                                            {t('synchronize.sync_now')}
                                        </Button>
                                    </div>
                                    <Header as="h4">{t('synchronize.admins')}</Header>
                                    {Object.keys(details.exact.divisions).map((division, i) => {
                                        const divisionId = Object.values(details.exact.divisions)[i];

                                        return (
                                            <Segment key={`div-${divisionId}`}>
                                                <Checkbox
                                                    label={division}
                                                    disabled={details.exact.current_division}
                                                    checked={divisionId === details.exact.current_division}
                                                    onChange={details.exact.current_division ? () => this.setDivision(divisionId as number, 'exact') : undefined}
                                                />
                                            </Segment>
                                        );
                                    })}
                                </>)}
                            </Segment>
                            <Segment>
                                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                                    <img src={twinfields} alt="" style={{ width: 220 }} />
                                    {details.twinfield && details.twinfield.current_division ? (
                                        <>
                                        <Button positive>{t('synchronize.linked')}</Button>
                                        {auth.super_login && <Button basic onClick={() => this.disconnect('twinfield')}>Ontkoppelen</Button>}
                                        </>
                                    ) : (
                                        <a
                                            className="ui basic button"
                                            href={`${config.baseUrl}connect/twinfield?state=${auth.selected_customer ? auth.selected_customer.hash_id : ''}`}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {t('synchronize.link')}
                                        </a>
                                    )}
                                </div>
                                {details.twinfield && Object.keys(details.twinfield.divisions).length > 0 &&  (<>
                                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                                        <Button
                                            basic
                                            onClick={() => this.startSync('twinfield')}
                                        >
                                            {t('synchronize.sync_now')}
                                        </Button>
                                    </div>
                                    <Header as="h4">{t('synchronize.admins')}</Header>
                                    {Object.keys(details.twinfield.divisions).map((division, i) => {
                                        const divisionId = Object.values(details.twinfield.divisions)[i];

                                        return (
                                            <Segment key={`div-${divisionId}`}>
                                                <Checkbox
                                                    label={division}
                                                    disabled={details.twinfield.current_division}
                                                    checked={divisionId === details.twinfield.current_division}
                                                    onChange={!details.twinfield.current_division ? () => this.setDivision(divisionId as number, 'twinfield') : undefined}
                                                />
                                            </Segment>
                                        );
                                    })}
                                </>)}
                            </Segment>
                            <Segment>
                                <div style={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'space-between' }}>
                                    <img src="https://www.nocore.nl/wp-content/themes/nocore/img/nocore-logo.png" alt="" style={{ width: 120 }} />
                                    {details.nocore && details.nocore.current_division &&  (<>
                                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                                            <Button
                                                basic
                                                onClick={() => this.startSync('nocore')}
                                            >
                                                {t('synchronize.sync_now')}
                                            </Button>
                                        </div>
                                    </>)}
                                </div>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <Header as="h3">{t('synchronize.manual')}</Header>
                                <p>{t('synchronize.copy')}</p>
                                <a className="ui basic button" href="/format.csv" target="_blank">
                                    <Icon name="download" />
                                    {t('synchronize.download_format')}
                                </a>
                            </Segment>
                            <Segment>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: 1 }}>
                                        <Input
                                            accept=".csv"
                                            fluid
                                            type="file"
                                            onChange={(e: any) => this.setState({
                                                uploadFile: e.target.files[0],
                                            })}
                                        />
                                    </div>
                                    <Button primary style={{ marginLeft: 5 }} onClick={this.startCsvSync}>{t('synchronize.sync')}</Button>
                                </div>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default withTranslation('common')(connect((state: AppState) => ({
    auth: state.auth.user,
}), {})(ImportView));
