import React from 'react';
import { Segment, Menu, Button, Dropdown } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getToken, request } from '../../api';
import { currencies } from '../../lib/constants';
import { money, number } from '../../lib/utils';
const config = require('../../config.json');

interface ActionsSentProps {
    history?: any,
    currency: string,
    globalFilter: {
        query: string,
        trigger: number,
        r9: boolean,
    }
}

interface ActionsSentState {
    isLoading: boolean,
    chartOptions: any,
    status?: any,
    format: string,
    year: number,
    years: number[],
    type: string,
    stack: boolean,
    showStatus: string,
    lc?: string,
}

const colors: any = {
    'Herinnering' : 'rgb(41, 111, 217)',
    'Aanmaning' : 'rgb(158, 109, 232)',
    'Aanmaning 2' : 'rgb(104, 41, 194)',
    'Sommatie' : 'rgb(222, 0, 0)',
    'Sommatie 2' : 'rgb(222, 0, 0)',
    'Sommatie 3' : 'rgb(222, 0, 0)',
}

const statusOptions: any = [
    {key: 'Alle statusssen', value: 'All', text: 'Alle statussen'},
    {key: 'Herinnering', value: 'Herinnering', text: 'Herinnering'},
    {key: 'Aanmaning', value: 'Aanmaning', text: 'Aanmaning'},
    {key: 'Aanmaning 2', value: 'Aanmaning 2', text: 'Aanmaning 2'},
    {key: 'Sommatie', value: 'Sommatie', text: 'Sommatie'},
    {key: 'Sommatie 2', value: 'Sommatie 2', text: 'Sommatie 2'},
    {key: 'Sommatie 3', value: 'Sommatie 3', text: 'Sommatie 3'},
]

class ActionsSent extends React.Component<ActionsSentProps & WithTranslation, ActionsSentState> {
    constructor(props: any) {
        super(props);
   
        // const self = this;
        const lc = localStorage.getItem('global-currency');

        this.state = {
            lc: lc ? lc : '€',
            isLoading: false,
            status: undefined,
            year: new Date().getFullYear(),
            years: [],
            format: 'm',
            type: 'amount',
            stack: true,
            showStatus: 'All',
            chartOptions: {
                credits: {
                    enabled: false,
                },
                chart: {
                    type: 'column',
                    zoomType: 'x',
                },
                title: {
                    text: ''
                },
                tooltip: {
                    shared: true
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true,
                        }
                    },
                    series: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: function (event: any) {
                                    //
                                },
                            },
                        }
                    },
                },
                xAxis: {
                    categories: [],
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    stackLabels: {
                        enabled: true,
                    }
                },
                series: []
            },
        };
    }

    componentDidMount = () => {
        this.fetch();
    }

    componentWillReceiveProps = (props: ActionsSentProps) => {
        if (props.currency !== this.props.currency) {
            this.fetch(props.currency);
        }
        if (props.globalFilter.trigger !== this.props.globalFilter.trigger) {
            this.fetch();
        }
    }
    
    fetch = (c?: string) => {
        const { props } = this;
        const currency = c || props.currency;
        const { year, format, type, stack, showStatus } = this.state;
        this.setState({ isLoading: true });
        const lc = localStorage.getItem('global-currency');

        const self = this;
        const q = this.props.globalFilter.query;
        const r9 = this.props.globalFilter.r9;
        const url = `dashboard/actions-sent?year=${year}&format=${format}&currency=${currency}&showStatus=${showStatus}&type=${type}&q=${q}${r9 ? `&r9=1` : ''}`;
        
        request.get(url).then(({ data }) => {
            const series: any = [];

            Object.keys(data.data).map((key: string, index: number) => {
                series.push({
                    name: props.t(`status.${key}`),
                    data: Object.values(data.data)[index],
                    color: colors[key],
                });
                return key;
            });

            let plotOptions: any = {
                tooltip: {
                    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                    pointFormatter: function () {
                        const self: any = this;
                        return `<span style="color:${self.series.color}">${self.series.name}</span>: <b>${number(self.y)}</b> (${number(self.percentage, 2)}%)<br />`;
                    },
                    shared: true
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                const self: any = this;
                                return number(self.point.y);
                            }
                        }
                    },
                },
            };
            
            if (type === 'amount') {
                plotOptions = {
                    tooltip: {
                        // pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>' + (currencies[this.props.currency]||lc||'€') + ' {point.y:.2f}</b> ({point.percentage:.0f}%)<br/>',
                        pointFormatter: function () {
                            const self: any = this;
                            return `<span style="color:${self.series.color}">${self.series.name}</span>: <b>${money(self.y, 2, currencies[props.currency || 'EUR'])}</b> (${number(self.percentage, 2)}%)<br />`;
                        },
                        footerFormat: '<br /><br />Klik op de kolom om de gegevens te exporteren',
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                                // format: (currencies[this.props.currency]||lc||'€')+' {point.y:.2f}',
                                formatter: function () {
                                    const self: any = this;
                                    return money(self.point.y, 2, currencies[props.currency || 'EUR']);
                                }
                            }
                        },
                        series: {
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: function (event: any) {
                                        const { globalFilter } = self.props;
                                        const { showStatus } = self.state;
                                        const data = this as any;
                                        
                                        window.open(`${config.baseUrl}dashboard/actions-sent?year=${year}&format=${format}&currency=${currency}&showStatus=${showStatus}&type=${type}&export=1&_token=${getToken()}&dt=${data.category}&df=${globalFilter.query || ''}${globalFilter.r9 ? '&r9=1' : ''}`, '_new');
                                    },
                                },
                            }
                        },
                    },
                }
            }

            this.setState({
                isLoading: false,
                status: data.status,
                years: data.years,
                chartOptions: {
                    xAxis: {
                        categories: data.categories,
                    },
                    series,
                    ...plotOptions,
                },
            })
        });
    }

    switchType = (type: string) => {
        this.setState({
            type,
        }, this.fetch);
    }

    // render
    render = () => {
        const { t } = this.props;
        const { chartOptions, format, years, year, isLoading, type, stack, showStatus, lc } = this.state;

        return (
            <div>
                <Menu compact style={{ marginRight: 15 }}>
                    <Dropdown
                        item
                        options={statusOptions.map((o: any) => ({ ...o, text: t(`status.${o.text}`)}))}
                        value={showStatus}
                        onChange={(e: any, data: any) => this.setState({ showStatus: data.value }, this.fetch)}
                    />
                </Menu>
                <Menu compact>
                    <Menu.Item
                        style={format === 'Y' ? { border: 'solid 1px #dd0000' } : {}}
                        onClick={() => this.setState({ format: 'Y' }, this.fetch)}
                    >
                        {t('dashboard.year')}
                    </Menu.Item>
                    <Menu.Item
                        style={format === 'm' ? { border: 'solid 1px #dd0000' } : {}}
                        onClick={() => this.setState({ format: 'm' }, this.fetch)}
                    >
                        {t('dashboard.month')}
                    </Menu.Item>
                    <Menu.Item
                        style={format === 'Y-m-d' ? { border: 'solid 1px #dd0000' } : {}}
                        onClick={() => this.setState({ format: 'Y-m-d' }, this.fetch)}
                    >
                        {t('dashboard.day')}
                    </Menu.Item>
                </Menu>
                {format !== 'Y' && <Menu compact style={{ marginLeft: 15 }}>
                    {years.map((y) => (
                        <Menu.Item
                            style={year === y ? { border: 'solid 1px #dd0000' } : {}}
                            onClick={() => this.setState({ year: y }, this.fetch)}
                        >
                            {y}
                        </Menu.Item>
                    ))}
                </Menu>}
                <Menu compact style={{ marginLeft: 15 }}>
                    <Menu.Item
                        style={type === 'count' ? { border: 'solid 1px #dd0000' } : {}}
                        onClick={() => this.switchType('count')}
                    >
                        #
                    </Menu.Item>
                    <Menu.Item
                        style={type === 'amount' ? { border: 'solid 1px #dd0000' } : {}}
                        onClick={() => this.switchType('amount')}
                    >
                        {lc}
                    </Menu.Item>
                </Menu>
                {/* <Button
                    style={!stack ? { border: 'solid 1px #dd0000', marginLeft: 15 } : {marginLeft: 15}}
                    onClick={() => this.setState({ stack: !stack }, this.fetch)}
                    icon="chart bar outline"
                    basic
                /> */}
                <Segment loading={isLoading}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </Segment>
            </div>
        );
    }
}

export default withTranslation('common')(ActionsSent);
